<template>
  <ElvMessageBox
    ref="reSubscriptionMessageBoxRef"
    class="elv-apply-project-re-subscription-dialog"
    :confirmButtonText="t('common.resubscribe')"
    :cancelButtonText="t('button.cancel')"
    :title="t('common.resubscribe')"
    :loading="confirmLoading"
    @onCancelEvent="changeReSubscriptionDialogStatus"
    @onConfirmEvent="onClickConfirmReSubscription"
  >
    <template #content>
      <div class="elv-apply-project-un-subscription-dialog-content">
        {{ isHasWhetherWithProject ? t('message.reSubscriptionPaymentTip') : t('message.reSubscriptionTip') }}
      </div>
    </template>
  </ElvMessageBox>
</template>

<script setup lang="ts">
import { isEmpty } from 'lodash-es'
import { ElMessage } from 'element-plus'
import ProjectApi from '@/api/ProjectApi'
import { useEntityStore } from '@/stores/modules/entity'
import { useGlobalStore } from '@/stores/modules/global'
import { useProjectStore } from '@/stores/modules/project'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const globalStore = useGlobalStore()
const projectStore = useProjectStore()

const confirmLoading = ref(false)
const reSubscriptionMessageBoxRef = useTemplateRef('reSubscriptionMessageBoxRef')

const currentProject = computed(() => {
  if (!isEmpty(projectStore.projectDetail)) return projectStore.projectDetail
  const projectId = entityStore.entityDetail?.projectId || (route.params?.projectId as string) || ''
  return globalStore.projectDetail(projectId)
})

const isHasWhetherWithProject = computed(() => {
  return currentProject.value?.isUnpaidInvoice
})

const changeReSubscriptionDialogStatus = () => {
  reSubscriptionMessageBoxRef.value?.onCheckMessageBoxDialog()
}

/**
 * 更新项目信息
 */
const refreshProjectInfo = async () => {
  try {
    await globalStore.fetchProjectList()
    await projectStore.fetchProjectDetail(currentProject.value?.projectId || '')
  } catch (error: any) {
    ElMessage.error(error.message)
  }
}

const onClickConfirmReSubscription = async () => {
  try {
    confirmLoading.value = true
    await ProjectApi.resumeSubscriptionProject(currentProject.value?.projectId || '')
    await refreshProjectInfo()
    reSubscriptionMessageBoxRef.value?.onCheckMessageBoxDialog()
  } catch (error: any) {
    ElMessage.error(error.message)
  } finally {
    confirmLoading.value = false
  }
}

defineExpose({
  changeReSubscriptionDialogStatus
})
</script>

<style lang="scss" scoped></style>
